import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";
import { graphConfig } from "./authConfig";
import { TailSpin, Bars } from "react-loader-spinner";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [graphAssignmentData, setgraphAssignmentData] = useState(null);
    const [expireHours, setExpireHours] = useState(null);
    const [loading, setLoading] = useState(true);
    let id;

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            // callMsGraph().then(response => {setGraphData(response); })
            // .catch((e) => {
            //     console.log(e);
            //     if (e instanceof InteractionRequiredAuthError) {
            //         instance.acquireTokenRedirect({
            //             ...loginRequest,
            //             account: instance.getActiveAccount()
            //         });
            //     }
            // });
            RequestRegistration();
        }
    }, [inProgress, graphData, instance]);

    function RequestRegistration() {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            if(response.accessToken !== null && response.accessToken !== undefined)
            {
                setLoading(true);
                callMsGraph(response.accessToken).then(response1 => {setgraphAssignmentData(response1);
                    //console.log(response1);
                    if(!response1.hasOwnProperty("status") && response1.error.code === "InvalidRequestExistingGrant")
                    {
                        id = (response1.error.message).split(': ')[1];
                        // const expirationInfo = fetch(graphConfig.graphAssignmentEndpoint+id, {method: "GET", headers: { 'Authorization': `Bearer ` + response.accessToken }})
                        // .then((response2) => { return response2.json(); })
                        // .catch(error => {setgraphAssignmentData("error"); setExpireHours("0")})

                        const listOfAssignmentsForTheSignedInUser = fetch(graphConfig.graphAssignmentListEndpoint, {method: "GET", headers: { 'Authorization': `Bearer ` + response.accessToken }})
                        .then((response2) => { return response2.json(); })
                        .catch(error => {setgraphAssignmentData("error"); setExpireHours("0")})

                        const getList = async() => {
                            const z = await listOfAssignmentsForTheSignedInUser;
                            const list = z.value;
                            const activeAssignment = list.filter(function(item){
                                return item.id == id;
                            });
                            const expirationDate = activeAssignment[0].schedule.expiration.endDateTime;
                                var date1 = new Date(expirationDate);
                                var date2 = new Date();
                                const remainingTime = Math.round(((date1.getTime() - date2.getTime())/(1000 * 3600))*100)/100;
                                setExpireHours(remainingTime);
                        };

                        getList();
                        setLoading(false);
                    }
                    else
                    {
                        //id = response1.id;
                        setExpireHours("0");
                        setgraphAssignmentData(response1);
                        setLoading(false);
                    }
                })
                .catch(error => {setgraphAssignmentData("error"); setExpireHours("0")});
            }
            else
            {
                setgraphAssignmentData("error");
                setExpireHours("0");
            }
        }).catch((error) => {setgraphAssignmentData("error"); setExpireHours("0")
        }).finally( setLoading(false) );
    }

    return (
        <>
            { loading ? (
                <Bars color="#0071c5" height="50" width="50" radius="10" wrapperStyle={{alignItems:"center", justifyContent:"center", fontWeight:"bold"}} />
            ): (
                <p></p>
            )}

            {graphAssignmentData && expireHours && !loading ? (
                <ProfileData graphAssignmentData={graphAssignmentData} expireHours={expireHours} loading={loading}/>
            ) : (
                // <Button variant="secondary" onClick={RequestRegistration}>
                //     Request Registration
                // </Button>
                <p></p>
            )}
        </>
    );
};

const authRequest = {
    ...loginRequest
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
    return (
        <div className="App">
            <MsalAuthenticationTemplate authenticationRequest={authRequest} interactionType={InteractionType.Redirect} >
                <ProfileContent />
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Signing In....</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
