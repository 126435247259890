// import { Button } from "bootstrap";
import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ProfileData = (props) => {
    const { instance } = useMsal();
    //console.log(props);
    if(props.loading === false && props.graphAssignmentData !== "error")
    {
        if ((props.expireHours !== null && props.expireHours !== undefined && props.expireHours !== "") && (props.graphAssignmentData !== "error" && props.graphAssignmentData !== "UnAuthorized")) {
            return (
                <div id="profile-div">
                    <div style={{ textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem", fontSize: "large", textAlign: "center" }}>
                        {props.graphAssignmentData.status === "Accepted" ? 
                            <div> 
                                <p>You now have access for 24 hours to enroll your mobile device for Intel email and apps. <FaCheckCircle style={{color:"darkgreen", verticalAlign: "unset"}}></FaCheckCircle> </p>  
                            </div>
                            : 
                            <div>
                                {props.expireHours > 1 ?
                                    <div>
                                        <p>You have {props.expireHours} hours remaining to complete your enrollment. </p>
                                        <p>You can request access again when the current access expires.</p>
                                    </div>
                                    :
                                    <div>
                                        <p>You have {Math.ceil(props.expireHours * 60)} minutes remaining to complete your enrollment. </p>
                                        <p>You can request access again when the current access expires.</p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div style={{ textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem", fontSize: "large", textAlign: "center" }}>
                        <Button style={{ margin: "2%", backgroundColor: "#0071c5" }} variant="primary" className="ml-auto" href="https://intel.sharepoint.com/sites/MobileDevices/SitePages/Intune-Setup.aspx" target="_blank"> Go to the Mobile Device Setup Guide</Button>
                    </div>
                    <div style={{paddingLeft: "5rem", paddingRight: "7rem", fontSize: "medium", textAlign: "center"}}>
                        {props.graphAssignmentData.status === "Accepted" ?
                          <div>Please wait 5 minutes before enrolling.</div>  
                          :
                          <div></div>
                        }
                    </div>
                    {/* <div style={{ textAlign: "left", fontSize: "medium", textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem" }}>
                        <strong>Note: </strong>
                        <ul>
                            <li>If you were directed to this page by someone unknown to you, it may be an attempt to access your account, <a href="https://intelait.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D11d4767edf74909053480394c2388976" target="_blank">report this issue</a> now.</li>
                            <li>If you want to remove access before it expires, go to <a href="https://myaccess.microsoft.com/@intel.onmicrosoft.com#/access-packages/active/" target="_blank">MyAccess.Microsoft.com</a>. Select and click on "Remove Access".</li>
                        </ul>
                    </div> */}
                </div>
            );
        }
        else if(props.graphAssignmentData === "UnAuthorized")
        {
            return (
                <div id="profile-div">
                    <p style={{ textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem", fontSize: "large" }}>
                        {<ul>
                                <li>You previously received access to enroll your mobile device for Intel email and apps.</li>
                                <li>You can request access again when the current access expires.</li>
                            </ul>}
                    </p>
                    <div>
                        <Button style={{ margin: "2%", backgroundColor: "#0071c5" }} variant="primary" className="ml-auto" href="https://intel.sharepoint.com/sites/MobileDevices/SitePages/Intune-Setup.aspx" target="_blank"> Go to the Mobile Device Setup Guide</Button>
                    </div>
                    {/* <p style={{ textAlign: "left", fontSize: "medium", textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem" }}>
                        <strong>Note: </strong>
                        <ul>
                            <li>If you were directed to this page by someone unknown to you, it may be an attempt to access your account, <a href="https://intelait.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D11d4767edf74909053480394c2388976" target="_blank">report this issue</a> now.</li> 
                            <li>If you want to remove access before it expires, go to <a href="https://myaccess.microsoft.com/@intel.onmicrosoft.com#/access-packages/active/" target="_blank">MyAccess.Microsoft.com</a>. Select and click on "Remove Access".</li>
                        </ul>
                    </p> */}
                </div>
            );
        }
    }
    else {
        return (
            <div id="profile-div">
                <p style={{ textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem" }}>
                    We're sorry, there was an issue giving you access to enroll your mobile device for Intel email and apps. Please retry again after sometime.
                </p>
                {/* <p style={{ textAlign: "left", fontSize: "medium", textAlign: "justify", paddingLeft: "5rem", paddingRight: "5rem" }}>
                    <strong>Note: </strong>
                    <ul>
                        <li>If you were directed to this page by someone unknown to you, it may be an attempt to access your account, <a href="https://intelait.service-now.com/nav_to.do?uri=%2Fcom.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D11d4767edf74909053480394c2388976" target="_blank">report this issue</a> now.</li>
                    </ul>
                </p> */}
            </div>
        );
    }
};
