import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const requestBody = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': bearer },
        body: JSON.stringify({
            "requestType": "userAdd",
            "assignment": {
                "accessPackageId": "96372faf-4df7-472a-927f-4005dab5167b"
                //"accessPackageId": "285acbbc-7cbe-49b6-bc6c-3e938922526a"
            }
        })
    }  
    
    return fetch(graphConfig.graphAssignmentRequestEndpoint, requestBody)
            .then(response => response.json())
            .catch(error => error.json())
    ;

    
}
